import React, { ReactNode } from 'react'

interface TBubble {
    children: ReactNode
    className?: string
}

function Bubble({ children, className }: TBubble) {
    return <div className={`bubble ${className}`}>{children}</div>
}

export default Bubble
