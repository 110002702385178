import { AccentButton } from '@/components/atoms/Button/AccentButton'
import InputField from '@/components/atoms/InputField'
import PasswordField from '@/components/atoms/InputField/PasswordField'
import { useTypedForm } from '@/hooks/useTypedForm'
import { TAllFormValues } from '@/react-hook-form/types'
import { routes } from '@/routes'
import { authStore } from '@/store/slices/authSlice'
import LoginRoundedIcon from '@mui/icons-material/LoginRounded'
import { Link } from 'gatsby'
import React from 'react'
import { Controller, SubmitHandler } from 'react-hook-form'
import { useSelector } from 'react-redux'

export default function LoginForm(props: {
    errorsText?: string[] | string
    onSubmit: SubmitHandler<TAllFormValues['Login']>
}) {
    const { loading } = useSelector(authStore)
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useTypedForm('Login')

    return (
        <form className="login-form m-auto-0 width-450" onSubmit={handleSubmit(props.onSubmit)}>
            <h4 className="fw-600 fz-20 text-center">ログイン</h4>
            <div className="form-content display-flex flex-column">
                <Controller
                    render={({ field: { ref, ...rest } }) => {
                        return (
                            <InputField
                                {...rest}
                                size="medium"
                                id="email"
                                error={!!errors.email?.message ?? false}
                                helperText={errors.email?.message}
                            />
                        )
                    }}
                    name="email"
                    control={control}
                />
                <Controller
                    name="password"
                    control={control}
                    render={({ field: { ref, ...rest } }) => (
                        <PasswordField {...rest} size="medium" error={!!errors.password?.message ?? false} />
                    )}
                />
            </div>
            <div className="text-right mt-10">
                <Link to={routes.forgotPassword} className="forget-password display-inline-block fz-13">
                    パスワードをお忘れの方はこちら
                </Link>
            </div>
            <div className="error-text mt-20 fz-14 fw-600 text-center">{props.errorsText}</div>
            <div className="text-center mt-30">
                <AccentButton type="submit" startIcon={<LoginRoundedIcon fontSize="large" />} disabled={loading}>
                    ログイン
                </AccentButton>
            </div>
        </form>
    )
}
