import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, InputAdornment, OutlinedInput, OutlinedInputProps } from '@mui/material'
import React, { useState } from 'react'
import ErrorText from '../ErrorText'

export default function PasswordField({ helperText, ...props }: OutlinedInputProps & { helperText?: string }) {
    const [showPassword, setShowPassword] = useState(false)
    const handleClickShowPassword = () => setShowPassword(!showPassword)
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    return (
        <div>
            <OutlinedInput
                {...props}
                fullWidth
                className={`${props.className} input-field`}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            {helperText && <ErrorText sx={{ margin: '4px 0 0 14px' }} text={helperText} />}
        </div>
    )
}
