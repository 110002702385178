import { Button, ButtonProps } from '@mui/material'
import React from 'react'

export function AccentButton(props: ButtonProps) {
    return (
        <Button {...props} className={`${props.className} accent-btn font-default`}>
            {props.children}
        </Button>
    )
}
