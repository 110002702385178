import Bubble from '@/components/atoms/Bubble'
import LoginForm from '@/components/molecules/Form/login'
import ReactHookForm from '@/providers/ReactHookForm'
import { TAllFormValues } from '@/react-hook-form/types'
import { schemaLogin } from '@/react-hook-form/validations'
import { AppDispatch } from '@/store'
import { authStore, userLogin, userLogout } from '@/store/slices/authSlice'
import translate from '@/utils/translations'
import { Auth } from '@aws-amplify/auth'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

function Login() {
    const [errors, setErrors] = useState('')
    const { error: errorText } = useSelector(authStore)
    const dispatch = useDispatch<AppDispatch>()

    const handleSubmit = async (formUser: TAllFormValues['Login']) => {
        try {
            const awsWser = await Auth.signIn(formUser.email, formUser.password)
            if (awsWser.challengeName === 'NEW_PASSWORD_REQUIRED') {
                await Auth.completeNewPassword(awsWser, formUser.password)
                return dispatch(userLogin(formUser))
            } else {
                return dispatch(userLogin(formUser))
            }
        } catch (err) {
            const translation = translate((err as any).message)
            if (translation) setErrors(translation)
        }
    }

    const defaultValue = {
        email: '',
        password: '',
    }

    return (
        <div className="flex-center flex-column">
            <div className="flex-center pa-60">
                <img className="width-400" src="/assets/logo/logo_bk2.png" alt="logo.png" />
            </div>
            <Bubble className="width-600 pa-30">
                <ReactHookForm validateSchema={schemaLogin} defaultValues={defaultValue}>
                    <LoginForm errorsText={errors || errorText} onSubmit={handleSubmit} />
                </ReactHookForm>
            </Bubble>
        </div>
    )
}

export default Login
